.playarea .column {
    padding: 8px !important;
    border: 2px solid rgba(0,0,0,0);
}

.dice {
    display: block;
    height: 45px;
    width: 44px;
    margin-bottom: 7px;
    background: url('/public/images/slot.png') 0 0;
}

.dice > .ui.mini.label {
    margin-top: 12px !important;
    margin-left: -20px !important;
}

.dice[data-value="1"] {
    background: url('/public/images/dice_sheet.png') 0 0;
}

.dice[data-value="2"] {
    background: url('/public/images/dice_sheet.png') -44px 0;
}

.dice1 {
    display: block;
    height: 45px;
    width: 44px;
    background: url('/public/images/dice_sheet.png') 0 0;
    margin-bottom: 7px;
}

.dice2 {
    display: block;
    height: 45px;
    width: 44px;
    background: url('/public/images/dice_sheet.png') -44px 0;
    margin-bottom: 7px;
}

.dice3 {
    display: block;
    height: 45px;
    width: 44px;
    background: url('/public/images/dice_sheet.png') -88px 0;
    margin-bottom: 7px;
}

.dice4 {
    display: block;
    height: 45px;
    width: 44px;
    background: url('/public/images/dice_sheet.png') -132px 0;
    margin-bottom: 7px;
}

.dice5 {
    display: block;
    height: 45px;
    width: 44px;
    background: url('/public/images/dice_sheet.png') -176px 0;
    margin-bottom: 7px;
}

.dice6 {
    display: block;
    height: 45px;
    width: 44px;
    background: url('/public/images/dice_sheet.png') -220px 0;
    margin-bottom: 7px;
}

.double[data-value]:not([data-value="0"]) {
    box-shadow: 0px 0px 3px 3px #c0c0c0;
}

.triple {
    box-shadow: 0px 0px 3px 3px #fff;
}