@font-face {
  font-family: 'BoneApart';
  src: url(/public/fonts/BoneApart.woff2) format('woff2');
}

.top.menu.fixed {
  height: 60px;
  transition: background-color 0.8s ease-in-out;
}

.rattle {
  color: #E3DAC9;
  font-family: BoneApart,sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;

  transition: unset;
  animation: squiggly-anim 650ms infinite alternate;

}

.top.menu .header.item:hover {
  animation: squiggly-anim 650ms infinite alternate;
  background-color: unset !important;
}

.ui.inverted.menu .item:before {
  background: unset !important;
}


@keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}

.ui.card.cardButton, .ui.cards>.ui.card.cardButton {
  background: #293040;
}

.ui.cards>.card.cardButton>.content>.header, .ui.cards>.card.cardButton>.content>.description {
  color: #E3DAC9;
}

.toastNotification .ui.items>.item>.content>.header, .toastNotification .ui.items>.item>.content>.description {
  color: #E3DAC9;
}

.ui.header {
  color: #E3DAC9 !important;
}